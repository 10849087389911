import React from 'react';
import {Grid, GridRow, Header} from 'semantic-ui-react';
import ReactPlayer from "react-player";


interface Props {
    readonly pageContext: {
        youtube_id: string,
        title: string
    }
}

export default class WorkoutPlayer extends React.Component<Props> {
    render() {
        let youtube_id = this.props.pageContext.youtube_id
        let title = this.props.pageContext.title
        let videoSrc = 'https://www.youtube.com/watch?v=' + youtube_id
        return (
            <Grid centered>
                <GridRow>
                    <Header as='h1' textAlign='center'>
                        {title}
                    </Header>
                </GridRow>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <ReactPlayer
                            width='100%'
                            url={videoSrc}
                            controls={true}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}
